export const domen = "https://internet-vitrina.ru/";

export async function getChannels() {
  const response = await fetch(domen + "api/getChannels.php");
  return response.json();
}

export async function addChannel(name, img, frame) {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("img", img);
  formData.append("frame", frame);
  const response = await fetch(domen + "api/addChannel.php", {
    body: formData,
    method: "POST",
  });
  return response.status;
}

export async function changeChannel(em) {
  let formData = new FormData();
  for (let key in em) {
    formData.append(key, em[key]);
  }
  const response = await fetch(domen + "api/changeChannel.php", {
    body: formData,
    method: "POST",
  });
  return response.status;
}

export async function uploadImage(file) {
  const data = new FormData();

  data.append("file", file);
  const response = await fetch(domen + "avatar.php", {
    method: "post",
    body: data,
  });
  return response.json();
}

export async function deleteChannel(id) {
  let formData = new FormData();
  formData.append("ID", id);
  const response = await fetch(domen + "api/deleteChannel.php", {
    body: formData,
    method: "POST",
  });
  return response.status;
}
