import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-creative";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";
import img6 from "./img/6.png";
import img7 from "./img/7.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Subscribe from "../../Components/Subscribe";
import { getChannels } from "../../Api";
// import required modules

export default function Main() {
  const [active, setActive] = useState(0);
  const Navigate = useNavigate();
  const [channels, setChannels] = useState([]);
  useEffect(() => {
    getChannels().then((data) => {
      setChannels(data);
    });
  }, []);
  return (
    <div className="Main">
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: false,
            translate: ["-20%", 0, -1],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
        className="channels"
        onActiveIndexChange={(index) => setActive(index.activeIndex)}
      >
        {channels.length > 0 &&
          channels.map((em, index) => {
            return (
              <SwiperSlide
                key={"channel" + index}
                onClick={() => {
                  Navigate("/channel?key=" + em.ID);
                }}
              >
                <img src={em.Img} alt="" />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div className="dots">
        {channels.length > 0 &&
          channels.map((em, index) => {
            return (
              <div
                style={
                  index == active
                    ? {
                        background: "#fff",
                        width: "5.5vw",
                        height: "5.5vw",
                      }
                    : {}
                }
                className="dot"
              ></div>
            );
          })}
      </div>
      <div className="time">У вас осталось 8 дней!</div>
      <svg
        className="grs"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="24"
          height="24"
          rx="12"
          fill="url(#paint0_linear_95_1083)"
        />
        <path
          d="M11.6685 16.0815C11.8516 16.2645 12.1484 16.2645 12.3315 16.0815L15.3146 13.0983C15.4976 12.9153 15.4976 12.6185 15.3146 12.4354C15.1315 12.2524 14.8347 12.2524 14.6517 12.4354L12 15.0871L9.34835 12.4354C9.16529 12.2524 8.8685 12.2524 8.68544 12.4354C8.50238 12.6185 8.50238 12.9153 8.68544 13.0983L11.6685 16.0815ZM11.5312 7.5L11.5312 15.75L12.4687 15.75L12.4688 7.5L11.5312 7.5Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_95_1083"
            x1="28.7805"
            y1="8.69999"
            x2="-9.97319"
            y2="9.01745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8052FF" />
            <stop offset="1" stop-color="#428EFF" />
          </linearGradient>
        </defs>
      </svg>
      <Subscribe></Subscribe>
    </div>
  );
}
