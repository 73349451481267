import "./style.css";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
export default function Subscribe() {
  return (
    <div className="Subscribe">
      <h3>Оформить подписку</h3>
      <div className="subscribeElem">
        <img src={img1} alt="" />
        <div className="subsBody">
          <article>Скидка 0%</article>
          <p>1 неделя</p>
          <span>1.000 руб</span>
          <div className="sbsBtn">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.83191 6.92738C4.93795 6.92738 5.04453 6.89131 5.13187 6.81762C5.32828 6.65177 5.35318 6.35809 5.18733 6.16176C4.70423 5.58953 4.43824 4.86131 4.43824 4.1112C4.43824 2.3575 5.86498 0.930839 7.61861 0.930839C9.37223 0.930839 10.799 2.3575 10.799 4.1112C10.799 4.60052 10.691 5.0695 10.4781 5.50545C10.3652 5.73645 10.4609 6.01508 10.6919 6.12787C10.9228 6.24104 11.2016 6.14493 11.3143 5.91401C11.59 5.34976 11.7297 4.74324 11.7297 4.1112C11.7297 1.84422 9.88551 0 7.61853 0C5.35155 0 3.50732 1.84422 3.50732 4.1112C3.50732 5.08083 3.85135 6.02229 4.47594 6.76216C4.56809 6.87122 4.69957 6.92738 4.83191 6.92738Z"
                fill="white"
              />
              <path
                d="M16.3781 10.9967C16.0782 9.22341 14.8014 8.9824 14.2514 8.96254C14.0957 8.95688 13.9538 8.87496 13.8704 8.74348C13.4541 8.08755 12.8895 7.91496 12.4111 7.91496C12.192 7.91488 11.9911 7.95103 11.8304 7.99354C11.7833 8.00595 11.7353 8.01207 11.6878 8.01207C11.5188 8.01207 11.356 7.93497 11.2543 7.79403C10.8448 7.2266 10.3102 7.07317 9.85202 7.07317C9.72946 7.07317 9.61233 7.08418 9.50459 7.10109C9.47581 7.10567 9.44726 7.10784 9.41926 7.10784C9.12605 7.10784 8.87868 6.8697 8.87829 6.56586L8.87441 3.28566C8.87332 2.43806 8.23764 2.01453 7.60195 2.01453C6.96503 2.01453 6.3281 2.43953 6.32919 3.28877C6.33089 4.71272 6.33609 8.98961 6.33756 10.2783C6.33787 10.4961 6.20763 10.6929 6.00673 10.7769C3.48253 11.832 6.59959 16.5651 7.15787 17.3762C7.21232 17.4554 7.24459 17.5471 7.2518 17.6429L7.38825 19.4783C7.4095 19.7614 7.64547 19.9999 7.92945 19.9999C7.92969 19.9999 7.92992 19.9999 7.93015 19.9999L14.0289 19.9728C14.3011 19.9724 14.5309 19.7609 14.5663 19.491L14.8147 17.5942C14.8253 17.5142 14.8543 17.435 14.898 17.3672C16.5279 14.8478 16.656 12.6403 16.3781 10.9967Z"
                fill="white"
              />
            </svg>
            Оплатить
          </div>
        </div>
      </div>
      <div className="subscribeElem">
        <img src={img2} alt="" />
        <div className="subsBody">
          <article>Скидка 25%</article>
          <p>1 месяц</p>
          <span>3.000 руб</span>
          <div className="sbsBtn">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.83191 6.92738C4.93795 6.92738 5.04453 6.89131 5.13187 6.81762C5.32828 6.65177 5.35318 6.35809 5.18733 6.16176C4.70423 5.58953 4.43824 4.86131 4.43824 4.1112C4.43824 2.3575 5.86498 0.930839 7.61861 0.930839C9.37223 0.930839 10.799 2.3575 10.799 4.1112C10.799 4.60052 10.691 5.0695 10.4781 5.50545C10.3652 5.73645 10.4609 6.01508 10.6919 6.12787C10.9228 6.24104 11.2016 6.14493 11.3143 5.91401C11.59 5.34976 11.7297 4.74324 11.7297 4.1112C11.7297 1.84422 9.88551 0 7.61853 0C5.35155 0 3.50732 1.84422 3.50732 4.1112C3.50732 5.08083 3.85135 6.02229 4.47594 6.76216C4.56809 6.87122 4.69957 6.92738 4.83191 6.92738Z"
                fill="white"
              />
              <path
                d="M16.3781 10.9967C16.0782 9.22341 14.8014 8.9824 14.2514 8.96254C14.0957 8.95688 13.9538 8.87496 13.8704 8.74348C13.4541 8.08755 12.8895 7.91496 12.4111 7.91496C12.192 7.91488 11.9911 7.95103 11.8304 7.99354C11.7833 8.00595 11.7353 8.01207 11.6878 8.01207C11.5188 8.01207 11.356 7.93497 11.2543 7.79403C10.8448 7.2266 10.3102 7.07317 9.85202 7.07317C9.72946 7.07317 9.61233 7.08418 9.50459 7.10109C9.47581 7.10567 9.44726 7.10784 9.41926 7.10784C9.12605 7.10784 8.87868 6.8697 8.87829 6.56586L8.87441 3.28566C8.87332 2.43806 8.23764 2.01453 7.60195 2.01453C6.96503 2.01453 6.3281 2.43953 6.32919 3.28877C6.33089 4.71272 6.33609 8.98961 6.33756 10.2783C6.33787 10.4961 6.20763 10.6929 6.00673 10.7769C3.48253 11.832 6.59959 16.5651 7.15787 17.3762C7.21232 17.4554 7.24459 17.5471 7.2518 17.6429L7.38825 19.4783C7.4095 19.7614 7.64547 19.9999 7.92945 19.9999C7.92969 19.9999 7.92992 19.9999 7.93015 19.9999L14.0289 19.9728C14.3011 19.9724 14.5309 19.7609 14.5663 19.491L14.8147 17.5942C14.8253 17.5142 14.8543 17.435 14.898 17.3672C16.5279 14.8478 16.656 12.6403 16.3781 10.9967Z"
                fill="white"
              />
            </svg>
            Оплатить
          </div>
        </div>
      </div>
      <div className="subscribeElem">
        <img src={img3} alt="" />
        <div className="subsBody">
          <article>Скидка 40%</article>
          <p>3 месяца</p>
          <span>7.000 руб</span>
          <div className="sbsBtn">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.83191 6.92738C4.93795 6.92738 5.04453 6.89131 5.13187 6.81762C5.32828 6.65177 5.35318 6.35809 5.18733 6.16176C4.70423 5.58953 4.43824 4.86131 4.43824 4.1112C4.43824 2.3575 5.86498 0.930839 7.61861 0.930839C9.37223 0.930839 10.799 2.3575 10.799 4.1112C10.799 4.60052 10.691 5.0695 10.4781 5.50545C10.3652 5.73645 10.4609 6.01508 10.6919 6.12787C10.9228 6.24104 11.2016 6.14493 11.3143 5.91401C11.59 5.34976 11.7297 4.74324 11.7297 4.1112C11.7297 1.84422 9.88551 0 7.61853 0C5.35155 0 3.50732 1.84422 3.50732 4.1112C3.50732 5.08083 3.85135 6.02229 4.47594 6.76216C4.56809 6.87122 4.69957 6.92738 4.83191 6.92738Z"
                fill="white"
              />
              <path
                d="M16.3781 10.9967C16.0782 9.22341 14.8014 8.9824 14.2514 8.96254C14.0957 8.95688 13.9538 8.87496 13.8704 8.74348C13.4541 8.08755 12.8895 7.91496 12.4111 7.91496C12.192 7.91488 11.9911 7.95103 11.8304 7.99354C11.7833 8.00595 11.7353 8.01207 11.6878 8.01207C11.5188 8.01207 11.356 7.93497 11.2543 7.79403C10.8448 7.2266 10.3102 7.07317 9.85202 7.07317C9.72946 7.07317 9.61233 7.08418 9.50459 7.10109C9.47581 7.10567 9.44726 7.10784 9.41926 7.10784C9.12605 7.10784 8.87868 6.8697 8.87829 6.56586L8.87441 3.28566C8.87332 2.43806 8.23764 2.01453 7.60195 2.01453C6.96503 2.01453 6.3281 2.43953 6.32919 3.28877C6.33089 4.71272 6.33609 8.98961 6.33756 10.2783C6.33787 10.4961 6.20763 10.6929 6.00673 10.7769C3.48253 11.832 6.59959 16.5651 7.15787 17.3762C7.21232 17.4554 7.24459 17.5471 7.2518 17.6429L7.38825 19.4783C7.4095 19.7614 7.64547 19.9999 7.92945 19.9999C7.92969 19.9999 7.92992 19.9999 7.93015 19.9999L14.0289 19.9728C14.3011 19.9724 14.5309 19.7609 14.5663 19.491L14.8147 17.5942C14.8253 17.5142 14.8543 17.435 14.898 17.3672C16.5279 14.8478 16.656 12.6403 16.3781 10.9967Z"
                fill="white"
              />
            </svg>
            Оплатить
          </div>
        </div>
      </div>
      <div className="subscribeElem">
        <img src={img4} alt="" />
        <div className="subsBody">
          <article>Скидка 50%</article>
          <p>6 месяцев</p>
          <span>12.000 руб</span>
          <div className="sbsBtn">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.83191 6.92738C4.93795 6.92738 5.04453 6.89131 5.13187 6.81762C5.32828 6.65177 5.35318 6.35809 5.18733 6.16176C4.70423 5.58953 4.43824 4.86131 4.43824 4.1112C4.43824 2.3575 5.86498 0.930839 7.61861 0.930839C9.37223 0.930839 10.799 2.3575 10.799 4.1112C10.799 4.60052 10.691 5.0695 10.4781 5.50545C10.3652 5.73645 10.4609 6.01508 10.6919 6.12787C10.9228 6.24104 11.2016 6.14493 11.3143 5.91401C11.59 5.34976 11.7297 4.74324 11.7297 4.1112C11.7297 1.84422 9.88551 0 7.61853 0C5.35155 0 3.50732 1.84422 3.50732 4.1112C3.50732 5.08083 3.85135 6.02229 4.47594 6.76216C4.56809 6.87122 4.69957 6.92738 4.83191 6.92738Z"
                fill="white"
              />
              <path
                d="M16.3781 10.9967C16.0782 9.22341 14.8014 8.9824 14.2514 8.96254C14.0957 8.95688 13.9538 8.87496 13.8704 8.74348C13.4541 8.08755 12.8895 7.91496 12.4111 7.91496C12.192 7.91488 11.9911 7.95103 11.8304 7.99354C11.7833 8.00595 11.7353 8.01207 11.6878 8.01207C11.5188 8.01207 11.356 7.93497 11.2543 7.79403C10.8448 7.2266 10.3102 7.07317 9.85202 7.07317C9.72946 7.07317 9.61233 7.08418 9.50459 7.10109C9.47581 7.10567 9.44726 7.10784 9.41926 7.10784C9.12605 7.10784 8.87868 6.8697 8.87829 6.56586L8.87441 3.28566C8.87332 2.43806 8.23764 2.01453 7.60195 2.01453C6.96503 2.01453 6.3281 2.43953 6.32919 3.28877C6.33089 4.71272 6.33609 8.98961 6.33756 10.2783C6.33787 10.4961 6.20763 10.6929 6.00673 10.7769C3.48253 11.832 6.59959 16.5651 7.15787 17.3762C7.21232 17.4554 7.24459 17.5471 7.2518 17.6429L7.38825 19.4783C7.4095 19.7614 7.64547 19.9999 7.92945 19.9999C7.92969 19.9999 7.92992 19.9999 7.93015 19.9999L14.0289 19.9728C14.3011 19.9724 14.5309 19.7609 14.5663 19.491L14.8147 17.5942C14.8253 17.5142 14.8543 17.435 14.898 17.3672C16.5279 14.8478 16.656 12.6403 16.3781 10.9967Z"
                fill="white"
              />
            </svg>
            Оплатить
          </div>
        </div>
      </div>
    </div>
  );
}
