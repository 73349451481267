import "./App.css";
import { Header } from "./Components/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import Main from "./Pages/Main";
import Channel from "./Pages/Channel";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { useEffect } from "react";
import Admin from "./Pages/Admin";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
function App() {
  const location = useLocation();
  useEffect(() => {
    if (window.Telegram) {
      let BackButton = window.Telegram.WebApp.BackButton;
      if (location.pathname != "/") {
        BackButton.show();
        BackButton.onClick(function () {
          window.history.back();
        });
      } else {
        BackButton.hide();
      }
    }
  }, [location.pathname]);
  return (
    <div className="App">
      <ReactNotifications />
      {location.pathname != "/" && (
        <BackButton onClick={() => window.history.back()} />
      )}
      {location.pathname != "/admin" && <Header></Header>}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/channel" element={<Channel />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </div>
  );
}

export default App;
