import { useEffect, useState } from "react";
import "./style.css";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

export function Header() {
  const WebApp = window.Telegram.WebApp;
  const [img, setImg] = useState(null);
  useEffect(() => {
    if (WebApp.initDataUnsafe.user) {
      fetch(
        "https://api.telegram.org/bot5629263536:AAGTQAGPdX_4lVrxGvsjEG3jk89JVUhRFhw/getUserProfilePhotos?user_id=" +
          WebApp.initDataUnsafe.user.id
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          fetch(
            "https://api.telegram.org/bot5629263536:AAGTQAGPdX_4lVrxGvsjEG3jk89JVUhRFhw/getFile?file_id=" +
              data.result.photos[0][0].file_id
          )
            .then((response) => {
              return response.json();
            })
            .then((path) => {
              setImg(path.result.file_path);
            });
        });
    }
  }, [WebApp]);

  return (
    <div className="header">
      <img
        src={
          img
            ? "https://api.telegram.org/file/bot5629263536:AAGTQAGPdX_4lVrxGvsjEG3jk89JVUhRFhw/" +
              img
            : ""
        }
        alt=""
      />

      <div className="hdInfo">
        <p>
          {WebApp &&
            WebApp.initDataUnsafe.user &&
            WebApp.initDataUnsafe.user.first_name}
        </p>
        <span>
          @
          {WebApp &&
            WebApp.initDataUnsafe.user &&
            WebApp.initDataUnsafe.user.username}
        </span>
      </div>
      <div className="buttonHd">
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.125 8.625C3.125 4.82804 6.20304 1.75 10 1.75C13.7969 1.75 16.875 4.82804 16.875 8.625V9.32875C17.9533 9.60631 18.75 10.5851 18.75 11.75V14.25C18.75 15.6307 17.6307 16.75 16.25 16.75C16.1059 16.75 15.9646 16.7378 15.8271 16.7144C14.9714 18.3414 13.2278 19.3394 11.3749 19.2438C11.3339 19.2479 11.2922 19.25 11.25 19.25H10C9.30962 19.25 8.75 18.6904 8.75 18C8.75 17.3096 9.30962 16.75 10 16.75H11.25C11.9027 16.75 12.4386 17.2502 12.4951 17.8883C13.4156 17.6502 14.216 17.0446 14.6846 16.1994C14.1147 15.7412 13.75 15.0382 13.75 14.25V11.75C13.75 10.5851 14.5468 9.60631 15.625 9.32875V8.625C15.625 5.5184 13.1066 3 10 3C6.89337 3 4.375 5.5184 4.375 8.625V9.32875C5.45327 9.60631 6.25 10.5851 6.25 11.75V14.25C6.25 15.6307 5.13071 16.75 3.75 16.75C2.36929 16.75 1.25 15.6307 1.25 14.25V11.75C1.25 10.5851 2.04673 9.60631 3.125 9.32875V8.625Z"
            fill="white"
          />
        </svg>
        Поддержка
      </div>
    </div>
  );
}
